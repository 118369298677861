import { CenterPricingType, CenterTimezone, CenterType, IndirectType } from 'models/center'
import { PeriodPriority } from 'models/center-period'

import i18n from './i18n'

export enum CentersFormTab {
  generalSettings,
  stockSettings,
  periodSettings,
  occupation,
  calendar,
  historic,
}

export const ROUNDING_OPTIONS = [
  { value: 0.01, text: '0.01 €' },
  { value: 0.05, text: '0.05 €' },
  { value: 0.1, text: '0.10 €' },
  { value: 0.5, text: '0.50 €' },
  { value: 1.0, text: '1.00 €' },
]

export const PRICING_TYPE_OPTIONS = [
  { value: CenterPricingType.DAILY, text: i18n.t`Día a día` },
  { value: CenterPricingType.PERIOD, text: i18n.t`Por periodo` },
]

export const CENTER_TYPE_OPTIONS = [
  { value: CenterType.AGGREGATION, text: i18n.t`Agregación` },
  { value: CenterType.TELPARK, text: i18n.t`Telpark` },
]

export const CENTER_INTEGRATION_OPTIONS = [
  { value: IndirectType.PARKINGSADMIN, text: i18n.t`Parkings Admin` },
  { value: IndirectType.EQUINSA, text: i18n.t`Equinsa` },
  { value: IndirectType.SKIDATA, text: i18n.t`Skidata` },
  { value: IndirectType.SKIDATA, text: i18n.t`I+D3` },
  { value: null, text: i18n.t`Ninguno` },
]

export const PERIOD_PRIORITY_COLORS: Record<PeriodPriority, string> = {
  [PeriodPriority.HIGHEST]: 'rgb(255, 77, 77)',
  [PeriodPriority.HIGH]: 'rgb(255, 173, 51)',
  [PeriodPriority.MEDIUM]: 'rgb(204, 204, 0)',
  [PeriodPriority.LOW]: 'rgb(0, 204, 68)',
  [PeriodPriority.DEFAULT]: 'rgb(179, 179, 179)',
}

export const PERIOD_PRIORITY_OPTIONS = [
  {
    value: PeriodPriority.HIGHEST,
    color: PERIOD_PRIORITY_COLORS[PeriodPriority.HIGHEST],
    text: i18n.t`HIGHEST` + ' (1)',
  },
  { value: PeriodPriority.HIGH, color: PERIOD_PRIORITY_COLORS[PeriodPriority.HIGH], text: i18n.t`HIGH` + ' (2)' },
  { value: PeriodPriority.MEDIUM, color: PERIOD_PRIORITY_COLORS[PeriodPriority.MEDIUM], text: i18n.t`MEDIUM` + ' (3)' },
  { value: PeriodPriority.LOW, color: PERIOD_PRIORITY_COLORS[PeriodPriority.LOW], text: i18n.t`LOW` + ' (4)' },
  {
    value: PeriodPriority.DEFAULT,
    color: PERIOD_PRIORITY_COLORS[PeriodPriority.DEFAULT],
    text: i18n.t`DEFAULT` + ' (5)',
  },
]

export const CENTER_TIMEZONES_OPTIONS = Object.values(CenterTimezone)
  .sort()
  .map(value => ({ value, text: value }))
