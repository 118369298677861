import styled, { css } from 'styled-components'

import { FontFamily, FontSizes } from 'config/constants'
import { colors } from 'config/theme'

export const PriceCardContainer = styled.div`
  position: relative;
  margin: 80px auto 0;
  border-radius: 100px;
  border: 2px solid ${colors.orange};
  padding: 20px;
  width: 800px;
  text-align: center;
  padding-bottom: 2rem;
`

export const AvailableParkingContainer = styled.div`
  position: absolute;
  top: -10px;
  left: 0px;
  padding: 10px 30px;
  background-color: ${colors.green};
  border-radius: 30px;
  text-align: center;
  color: ${colors.white};
  font-size: ${FontSizes.lg};
  font-family: ${FontFamily.medium};
`

export const PriceContainer = styled.p`
  color: ${colors.deepBlue};
  font-family: ${FontFamily.bold};
  font-size: 2rem;
  text-align: center;
  line-height: 1rem;
  text-transform: uppercase;

  strong {
    color: ${colors.orange};
  }
`

export const CenterContainer = styled.p`
  color: ${colors.deepBlue};
  font-family: ${FontFamily.medium};
  font-size: ${FontSizes.xl};
  text-align: center;
  text-transform: uppercase;
  white-space: nowrap;
  overflow: hidden;
  max-width: 100%;
  position: relative;

  &:hover span {
    animation: scrollText 12s linear infinite;
  }

  span {
    display: inline-block;
  }

  @keyframes scrollText {
    0% {
      transform: translateX(0%);
    }
    25% {
      transform: translateX(-50%);
    }
    50% {
      transform: translateX(0%);
    }
    75% {
      transform: translateX(50%);
    }
    100% {
      transform: translateX(0%);
    }
  }
`

export const DatesContainer = styled.p`
  color: ${colors.deepBlue};
  font-family: ${FontFamily.bold};
  font-size: ${FontSizes.xl};
  text-align: center;
  text-transform: uppercase;
  display: flex;
  gap: 2rem;
  justify-content: center;

  strong {
    color: ${colors.orange};
    font-family: ${FontFamily.semiBold};
  }
`

export const InfoContainer = styled.div`
  display: flex;
  justify-content: space-around;
  margin-bottom: 2rem;

  div {
    text-align: center;
  }
`

export const InfoTitleContainer = styled.p`
  color: ${colors.deepBlue};
  font-family: ${FontFamily.bold};
  font-size: ${FontSizes.lg};
  text-align: center;
  text-transform: uppercase;
`

interface InfoTextContainerProps {
  green?: boolean
  red?: boolean
}

export const InfoTextContainer = styled.p`
  color: ${colors.deepBlue};
  font-family: ${FontFamily.medium};
  font-size: ${FontSizes.base};
  text-align: center;
  text-transform: uppercase;
  margin: 0;
  line-height: 1.4rem;

  ${(props: InfoTextContainerProps) =>
    props.green &&
    css`
      color: ${colors.green};
    `}
  ${(props: InfoTextContainerProps) =>
    props.red &&
    css`
      color: ${colors.red};
    `}
`
